<template>
  <div>
<!--    <vs-alert color="primary" icon="info" active="true" class="mt-5">-->
<!--      <p>Arquivos aceitos sao: JPG, JPEG, JPG, PNG, GIF, XLS, XLSX, DOC, DOCX, PDF, TXT</p>-->
<!--    </vs-alert>-->

    <div class="mt-5">
      <vs-upload :fileName="fileName"
                 text="Clique aqui para enviar arquivos"
                 :action="isAction"
                 @on-error="failedUpload"
                 @on-success="successUpload"
                 @on-delete="deleteFile"
                 show-upload-button
                 single-upload
                 multiple
                 :headers="getHeaders"
                 :data="dataFile"
                />
    </div>

  </div>
</template>

<script>

  export default {
    props: {
      type: {
        type: String,
        required: true
      }
    },
    data() {
      return {
        action: '',
      }
    },
    computed: {
      isAction() {
        return `${process.env.VUE_APP_ROOT_API}/contracts/files`
      },
      dataFile() {
        return {type: this.type}
      },
      fileName(){
        return 'file'
      },
      getHeaders() {
        return {
          'Access-Control-Allow-Origin': "*",
        }
      }
    },
    methods: {
      deleteFile() {

      },
      failedUpload() {
        this.$vs.notify({color: 'danger', title: 'Upload Failed', text: 'Não foi possível fazer o upload!'})
      },
      successUpload(event) {
        this.$store.dispatch("moduleFiles/uploadFile" , event)
        this.$vs.notify({color: 'success', title: 'Upload Success', text: 'Upload realizado com sucesso!'})
      }
    }
  }
</script>
