<template>
  <vs-table pagination maxItems="10" search :data="this.files">

    <template slot="thead">
      <vs-th sort-key="id">Id Arquivo</vs-th>
      <vs-th sort-key="filename">Nome Arquivo</vs-th>
      <vs-th sort-key="filename">Criado Em</vs-th>
      <vs-th>Ações</vs-th>
    </template>

    <template slot-scope="{data}">
      <vs-tr :key="indextr" v-for="(tr, indextr) in data">

        <vs-td :data="data[indextr].id">
          {{ data[indextr].id }}
        </vs-td>

        <vs-td :data="data[indextr].originalName">
          {{ data[indextr].originalName }}
        </vs-td>

        <vs-td :data="data[indextr].createdAt">
          {{ data[indextr].createdAt|formatDate }}
        </vs-td>

        <vs-td class="whitespace-no-wrap">
          <feather-icon icon="DownloadIcon"
                        svgClasses="w-5 h-5 hover:text-primary stroke-current"
                        @click="openUploadedFile(data[indextr].fileName)"
                        class="ml-2"
                        title="Donwload"/>
          <feather-icon v-if="type === 'notice' ? $acl.check('admin') : $acl.check('manager')"
                        icon="TrashIcon"
                        svgClasses="w-5 h-5 hover:text-danger stroke-current"
                        @click="deleteUploadedFile(data[indextr])"
                        class="ml-2"
                        title="Excluir"/>
        </vs-td>
      </vs-tr>
    </template>
  </vs-table>
</template>

<script>
  export default {
    props: {
      type: {
        required: true,
        type: String
      }
    },
    computed: {
      files() {
        return this.$store.state.moduleFiles.files
      }
    },
    methods: {
      openUploadedFile(fileName) {
        window.open(`${process.env.VUE_APP_ROOT_API}/contracts/seeUploadedFile/${fileName}`, '_blank')
      },
      deleteUploadedFile(file) {
        this.$store.dispatch("moduleFiles/deleteFile", file.id).then(() => {
          this.$vs.notify({color: 'success', title: 'Deletar Arquivo', text: 'Arquivo deletado com sucesso!'})
        }).catch(() => {
          this.$vs.notify({color: 'error', title: 'Deletar Arquivo', text: 'Não foi possível deletar o arquivo!'})
        })
      }
    },
    created() {
      this.$store.dispatch("moduleFiles/fetchFiles", this.type);
    }
  }
</script>
